import React, { useState } from 'react';
import './styles.css';

const SystemNetwork = ({ data }) => {
    const TITLE = "INFRASTRUKTURDATEN - EXPLOITATION - System Network"
    const [visible, setVisible] = useState(true);
    const toggleVisibility = () => {
        setVisible(!visible);
    }
    const head = ['Test', 'Status']
    const records = [
        ['xfreerdp /v <domain.com> -sec-nla /u:““', 'bestanden'],
        ['xfreerdp /u:administrator /g:grandbusiness /p:bla /v:192.168.1.34', 'bestanden'],
        ['ncrack -vv -user user -P wordlist.txt 192.168.0.32:3389', 'bestanden'],
        ['crowbar -b drp -u user -C password_wordlist -s 192.168.0.16/32 -v', 'bestanden'],
        ['pth-smbclient --user=user -pw-nt-hash -m smb3', 'bestanden'],
    ]
    return (
        <>
            <div className="component">
                <div className="component-title">
                    <div className="row">
                        <div className="col-auto upper">{TITLE}</div>
                        <div className="col text-end clickable toggler" onClick={toggleVisibility}>{visible ? "Hide" : "Show"}</div>
                    </div>
                </div>
                <div className="component-body" style={{display: visible ? "block" : "none"}}>
                    <div className="component-space">
                        <table className="table table-hover">
                            <thead>
                                {head?.map((item, index) => (
                                    <th>{item}</th>
                                ))}
                            </thead>
                            <tbody>
                                {records?.map((record, index) => (
                                    <tr>
                                        {record?.map((item, index) => (
                                            <td>{item}</td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SystemNetwork;