import React, { useState } from 'react';
import './styles.css';

const Darknet = ({ data }) => {
    const TITLE = "DARKNET & DATA BREACHES"
    const [visible, setVisible] = useState(true);
    const toggleVisibility = () => {
        setVisible(!visible);
    }
    let emailOccurrences = {};
    let emailOccurrencesHTML = {};
    data.darknet?.map((record, index) => {
        const email = record.Email;
        emailOccurrences[email] = (emailOccurrences[email] || 0) + 1;
    });

    return (
        <>
            <div className="component">
                <div className="component-title">
                    <div className="row">
                        <div className="col-auto upper">{TITLE}</div>
                        <div className="col text-end clickable toggler" onClick={toggleVisibility}>{visible ? "Hide" : "Show"}</div>
                    </div>
                </div>
                <div className="component-body" style={{display: visible ? "block" : "none"}}>
                    <div className="component-space">
                        <table className="table table-striped table-hover">
                            <thead>
                                <th>E-Mail Adresse</th>
                                <th>Breach Site</th>
                                <th>Breach Date</th>
                                <th>Sensitive?</th>
                            </thead>
                            <tbody>
                        
                            {data.darknet?.map((record, index) => {
                                let email = record.Email;
                                const displayEmail = emailOccurrencesHTML[email] === undefined ? email : '';
                                    emailOccurrencesHTML[email] = true;
                                return (
                                    <tr key={index}>
                                        {displayEmail ? (
                                            <td className="h6 w-25 text-center vertical-center" rowSpan={emailOccurrences[email]}>{displayEmail}</td>
                                        ) : null}
                                        <td>{record.Name}</td>
                                        <td>{record.BreachDate}</td>
                                        <td>{record.isSensitive ? "SENSITIVE" : "N"}</td>
                                    </tr>
                                );
                            })}

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Darknet;