import React, { useState } from 'react';
import './styles.css';

const PortScan = ({ data }) => {
    const TITLE = "Website Portscan"
    const [visible, setVisible] = useState(true);
    const toggleVisibility = () => {
        setVisible(!visible);
    }
    return (
        <>
            <div className="component">
                <div className="component-title">
                    <div className="row">
                        <div className="col-auto upper">{TITLE}</div>
                        <div className="col text-end clickable toggler" onClick={toggleVisibility}>{visible ? "Hide" : "Show"}</div>
                    </div>
                </div>
                <div className="component-body" style={{display: visible ? "block" : "none"}}>
                    <div className="component-space">
                        <table className="table table-hover text-center">
                            <thead>
                                <th>Port</th>
                                <th>Shortname</th>
                                <th className="text-start">Name</th>
                                <th>Status</th>
                            </thead>
                            <tbody>
                                {data.ports?.map((port, index) => (
                                    <tr key={index}>
                                        <td>{port[0]}</td>
                                        <td>{port[1]}</td>
                                        <td className="text-start">{port[2]}</td>
                                        <td className={port[3] === "closed" ? "text-success" : "text-danger"}>{port[3]}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PortScan;