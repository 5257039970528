export function calculateScore(data) {
  let score = 300;
  let letter = "F";

  // Virus Checking
  let virus_count = 0;
  // eslint-disable-next-line
  if (data?.virus_total) {
    for (const [key, value] of Object.entries(data.virus_total)) {
      if (!(value === "clean" || value === "unrated")) {
        virus_count += 1;
      }
    }
  }

  // Scam Checking
  let scam_count = 0;
  // eslint-disable-next-line
  if (data?.scamcheck) {
    for (const [key, value] of Object.entries(data.scamcheck)) {
      if (value === "Nicht OK") {
        scam_count += 1;
      }
    }
  }

  // Security Headers
  let security_headers = 0;
  // eslint-disable-next-line
  if (data?.securityheaders) {
    for (const [key, value] of Object.entries(data.securityheaders)) {
      if (value === "aktiv") {
        security_headers += 1;
      }
    }
  }

  // Open Ports
  let close_ports = 0;
  let dangerous_ports = 0;
  if (data?.portscan) {
    for (const value of data.portscan) {
      if (value[3] === "closed") {
        close_ports += 1;
        if (value[0] !== 80 && value[0] !== 443) {
          dangerous_ports += 1;
        }
      }
    }
  }
  // Scoring
  if (virus_count === 0) {
    score -= 100;
  }
  if (scam_count === 0) {
    score -= 100;
  }
  score -= 10 * security_headers;
  score -= 10 * close_ports;
  score += 25 * dangerous_ports;
  score += 25 * virus_count;

  // Additional Scoring
  score -= 10; // Anti-Virus
  score -= 10; // Firewall
  score -= 10; // Monitoring
  score += 10; // Google Fonts

  if (score >= 600) {
    letter = "I";
  } else if (score >= 500) {
    letter = "H";
  } else if (score >= 450) {
    letter = "G";
  } else if (score >= 400) {
    letter = "F";
  } else if (score >= 350) {
    letter = "E";
  } else if (score >= 250) {
    letter = "D";
  } else if (score >= 200) {
    letter = "C";
  } else if (score >= 150) {
    letter = "B";
  } else if (score >= 0) {
    letter = "A";
  }

  return {
    score: score,
    letter: letter,
    virus_count: virus_count,
    scam_count: scam_count,
    security_headers: security_headers,
    close_ports: close_ports,
    dangerous_ports: dangerous_ports,
  };
}
