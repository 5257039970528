import React, { useState } from 'react';
import './styles.css';

const SecurityHeaders = ({ data }) => {
    const TITLE = "Website - Security Header"
    const [visible, setVisible] = useState(true);
    const toggleVisibility = () => {
        setVisible(!visible);
    }

    let headers = [
        {
            name: "X-Frame-Options (XFO)",
            description: "Dieser Header verhindert, dass die Seite in einen Frame in einer anderen Seite geladen werden kann. Nicht unbedingt für die eigene Sicherheit, aber was Content-Diebstahl, Missbrauch etc. betrifft, sicher sehr hilfreich. Mit dem Wert »deny« wird keinerlei Laden in einem Frame zugelassen, mit »sameorigin« nur »in sich selbst«.",
            value: data.headerscheck["X-Frame-Options"]
        },
        {
            name: "X-Content-Type-Options",
            description: "Dem Browser wird normalerweise mitgeteilt, welche Dateiformate er jeweils laden soll, also z. B. HTML oder JPG. Wenn das nicht angegeben wird, soll der Browser »raten«. Das kann man ausnutzen und so andere Formate einschleusen, die Schaden anrichten können. Mit dem Setzen dieses Headers untersagt man dem Browser das »sniffen« und unterbindet diese Angriffsmöglichkeit.",
            value: data.headerscheck["X-Content-Type-Options"]
        },
        {
            name: "http Strict-Transport-Security (HSTS)",
            description: "Dieser Header informiert den Browser, dass die Seite nur per HTTPS aufgerufen werden darf. Voraussetzung ist hierbei natürlich, dass die Seite über ein SSL-Zertifikat verfügt. Über den Parameter »max-age« wird die Zeit (in Sekunden) angegeben, in der der Browser sich merken soll, das die Seite nur über HTTPS aufgerufen werden darf. Mit »includeSubdomains« werden alle Subdomains mit in diese Regel eingeschlossen.",
            value: data.headerscheck["HSTS"]
        },
        {
            name: "Referrer-Policy",
            description: "Dieser Header steuert, ob der Referrer-Wert bei ausgehenden Links übergeben werden darf. Das heißt, darf der Browser einer Seite mitteilen, von welcher Seite der Benutzer gekommen ist. Mit dem Wert »no-referrer« verbietet man das komplett.",
            value: data.headerscheck["Referrer-Policy"]
        },
        {
            name: "Permissions-Policy",
            description: "Dieser Header ist nicht direkt für die »Sicherheit« der Website verantwortlich aber in Bezug auf den Datenschutz schon interessant.",
            value: data.headerscheck["Permissions-Policy"]
        },
        {
            name: "Content-Security-Policy (CSP)",
            description: "Der Permissions-Policy-Header hieß früher »Feature-Policy« und wurde im Mai 2020 umbenannt. Auch die Syntax für die Header-Werte hat sich geändert. Dieser Header hat nicht so sehr die Sicherheit der Website oder der Betreiber im Mittelpunkt, sondern vielmehr die Datensicherheit des Besuchers. In heutigen Zeiten ein Feature, das man nicht hoch genug einschätzen kann. Man kann den Einsatz von Mikrofon oder Kamera, den Abruf des Standorts, die Nutzung der Vibrationsfunktion u. a. unterbinden oder zulassen.",
            value: data.headerscheck["CSP"]
        },
        {
            name: "X-XSS Protection",
            description: "Dieser Header verhindert das bekannte »Cross Site Scripting«, das gefürchtete Laden von fremden Scripten in die Website. Damit wird ggf. bösartiges JavaScript im Browser ausgeführt, ohne dass der Benutzer es überhaupt mitbekommt. Allerdings verursachte der Eintrag Sicherheits- und Datenschutz-Probleme, weshalb er von aktuellen Browsern gar nicht mehr unterstütz wird. Der Eintrag sollte daher auf „0“ gesetzt oder gleich ganz entfernt werden. Mit einer sauber eingerichteten Content Security Policy ist der X-XSS-Protection Header ohnehin überflüssig.",
            value: data.headerscheck["X-XSS Protection"]
        }
    ]

    return (
        <>
            <div className="component">
                <div className="component-title">
                    <div className="row">
                        <div className="col-auto upper">{TITLE}</div>
                        <div className="col text-end clickable toggler" onClick={toggleVisibility}>{visible ? "Hide" : "Show"}</div>
                    </div>
                </div>
                <div className="component-body" style={{display: visible ? "block" : "none"}}>
                    <div className="component-space">
                        {headers?.map((header, index) => (
                            <>
                                <div className="title">{header.name}</div>
                                <hr/>
                                <div className="row">
                                    <div className="row">
                                        <div className="col description">{header.description}</div>
                                        <div className={`col-auto m-2 centralize h-100 ${header.value === 'aktiv' ? "text-success" : "text-danger"}`}>{header.value}</div>
                                    </div>
                                </div>
                                {/* <hr/> */}
                            </>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};

export default SecurityHeaders;