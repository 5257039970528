import React, { useState } from 'react';
import './styles.css';

const ScamCheck = ({ data }) => {
    const TITLE = "Scam Check"
    const [visible, setVisible] = useState(true);
    const toggleVisibility = () => {
        setVisible(!visible);
    }

    let records = [];
    let record = [];
    const keys = Object.keys(data.scamcheck);
    for (let i=0; i<keys.length; i++) {
        record.push({key: keys[i], value: data.scamcheck[keys[i]]});
        if (record.length === 2) {
            records.push(record);
            record = [];
        }
    }

    return (
        <>
            <div className="component">
                <div className="component-title">
                    <div className="row">
                        <div className="col-auto upper">{TITLE}</div>
                        <div className="col text-end clickable toggler" onClick={toggleVisibility}>{visible ? "Hide" : "Show"}</div>
                    </div>
                </div>
                <div className="component-body" style={{display: visible ? "block" : "none"}}>
                    <div className="component-space">
                        <div className="title">Betrugsdatenbanken</div><hr/>
                        <table className="m-auto table table-hover">
                            <tbody>
                                {records?.map((record, index) => (
                                    <tr key={index}>
                                        <td>{record[0].key}</td>
                                        <td className={`${record[0].value === 'OK' ? "text-success" : record[0].value === "Nicht OK" ? "text-danger" : "text-secondary"}`}>{record[0].value}</td>
                                        <td>{record[1].key}</td>
                                        <td className={`${record[1].value === 'OK' ? "text-success" : record[1].value === "Nicht OK" ? "text-danger" : "text-secondary"}`}>{record[1].value}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ScamCheck;