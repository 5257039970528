import React, { useState } from 'react';
import { ComposableMap, Geographies, Geography } from "react-simple-maps";
import './styles.css';

const OffentlicheServer = ({ data }) => {
    const TITLE = "INFRASTRUKTURDATEN - ÖFFENTLICHE SERVER"
    const [visible, setVisible] = useState(true);
    const toggleVisibility = () => {
        setVisible(!visible);
    }

    return (
        <>
            <div className="component">
                <div className="component-title">
                    <div className="row">
                        <div className="col-auto upper">{TITLE}</div>
                        <div className="col text-end clickable toggler" onClick={toggleVisibility}>{visible ? "Hide" : "Show"}</div>
                    </div>
                </div>
                <div className="component-body" style={{display: visible ? "block" : "none"}}>
                    <div className="component-space">
                        <div className="row">
                            <div className="col">
                                <table className="table table-hover">
                                    <thead>
                                        <tr>
                                            <td className="title">Hosting (IP block owners)</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.dnsdumpster?.map((record, index) => (
                                            record[0] !== 'TXT' ? (
                                                <>
                                                    <tr key={index}>
                                                        <td>{record[0]}</td>
                                                        <td>{record[1]}</td>
                                                        <td>{record[2]}</td>
                                                        <td>{record[3]}</td>
                                                        <td>{record[4]}</td>
                                                    </tr>
                                                </>
                                            ) : (<></>)
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <div className="col">
                                <ComposableMap>
                                    <Geographies geography="/features.json">
                                        {({ geographies }) =>
                                        geographies?.map((geo) => (
                                            <Geography key={geo.rsmKey} geography={geo} />
                                        ))
                                        }
                                    </Geographies>
                                </ComposableMap>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default OffentlicheServer;