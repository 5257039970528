import React, { useState } from 'react';
import './styles.css';

const DNSRecords = ({ data }) => {
    const TITLE = "INFRASTRUKTURDATEN - DNS Records"
    const [visible, setVisible] = useState(true);
    const toggleVisibility = () => {
        setVisible(!visible);
    }
    return (
        <>
            <div className="component">
                <div className="component-title">
                    <div className="row">
                        <div className="col-auto upper">{TITLE}</div>
                        <div className="col text-end clickable toggler" onClick={toggleVisibility}>{visible ? "Hide" : "Show"}</div>
                    </div>
                </div>
                <div className="component-body" style={{display: visible ? "block" : "none"}}>
                    <div className="component-space">
                        <table className="w-100 table table-hover">
                            <thead>
                                <th>Domain</th>
                                <th>Type</th>
                                <th>Value</th>
                                <th>TTL</th>
                            </thead>
                            <tbody>
                                {data.dns_records?.map((record, index) => (
                                    <>
                                        <tr key={index}>
                                            <td className="table--key">{record.domain}</td>
                                            <td>{record.record}</td>
                                            <td title={record.value}>{record.value.slice(0, 30)}{record.value.length > 30 ? "..." : ""}</td>
                                            <td>{record.TTL} s</td>
                                        </tr>
                                    </>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DNSRecords;