import React from 'react';
import html2pdf from 'html3pdf';

class DownloadPDF extends React.Component {
  downloadPDF = () => {
    const element = document.getElementById('page-to-pdf');
    const opt = {
      margin:       0.05,
      filename:     'AUDIT-REPORT.pdf',
      image:        { type: 'jpeg', quality: 1 },
      html2canvas:  { scale: 1 },
      jsPDF:        { unit: 'in', format: 'letter', orientation: 'portrait' }
    };

    html2pdf().from(element).set(opt).save();
  }

  render() {
    return (
      <div>
        <button onClick={this.downloadPDF}>Download PDF</button>
        <div id="page-to-pdf">
          {/* Content of your page that you want to download as PDF */}
        </div>
      </div>
    );
  }
}

export default DownloadPDF;
