import React, { useState } from 'react';
import './styles.css';

const Cookies = ({ data }) => {
    const TITLE = "INFRASTRUKTURDATEN - COOKIE VULNERABILITIES"
    const [visible, setVisible] = useState(true);
    const toggleVisibility = () => {
        setVisible(!visible);
    }
    const head = ['Cookie', 'Ergebnis', 'Status']
    const records = [
        ['3a53ec2bebdaed2a6c903fa45ee50f', 'Secure and SameSite tags are missing.', 'nicht bestanden']
    ]
    return (
        <>
            <div className="component">
                <div className="component-title">
                    <div className="row">
                        <div className="col-auto upper">{TITLE}</div>
                        <div className="col text-end clickable toggler" onClick={toggleVisibility}>{visible ? "Hide" : "Show"}</div>
                    </div>
                </div>
                <div className="component-body" style={{display: visible ? "block" : "none"}}>
                    <div className="component-space">
                        <table className="table table-hover">
                            <thead>
                                {head?.map((item, index) => (
                                    <th key={index} >{item}</th>
                                ))}
                            </thead>
                            <tbody>
                                {records?.map((record, index) => (
                                    <tr key={"rec" + index}>
                                        <td title={record[0]}>{record[0].slice(0, 20)}{record[0].length > 20 ? "..." : ""}</td>
                                        <td>{record[1]}</td>
                                        <td>{record[2]}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Cookies;