import React, { useState } from 'react';
import ReactDOM from 'react-dom';
// Bootstrap
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
// Functions
import { calculateScore } from './functions/calculateScore.js';
import { getCurrentDate } from './functions/getCurrentDate.js';
// Components
import CSP from './components/CSP.js';
import Cookies from './components/Cookies.js';
import DNSRecords from './components/DNSRecords.js';
import Darknet from './components/Darknet.js';
import DomainMonitoring from './components/DomainMonitoring.js';
import Email from './components/Email.js';
import Export from './components/Export.js';
import Firmenidentifikation from './components/Firmenidentifikation';
import OffentlicheServer from './components/OffentlicheServer.js';
import PortScan from './components/PortScan.js';
import PostExploitation from './components/PostExploitation.js';
import PostExploitation2 from './components/PostExploitation2.js';
import SSLCheck from './components/SSLCheck.js';
import ScamCheck from './components/ScamCheck';
import Score from './components/Score';
import SecurityHeaders from './components/SecurityHeaders.js';
import Seriositat from './components/Seriositat';
import SocialMedia from './components/SocialMedia.js';
import SystemNetwork from './components/SystemNetwork.js';
import VirusAnalysis from './components/VirusAnalysis.js';
import VulnerabilitiesDetection from './components/VulnerabilitiesDetection.js';
import WebAppExploitation from './components/WebAppExploitation.js';
import Website from './components/Website';
// Style Files
import './App.css';


function App() {
  const backend_hostname = "/api"
  // const backend_hostname = "https://risikomonitor.ch:5003"
  let username = "Manuel Loew-Beer";
  let userid = 201;
  let companyName = "";
  const currentYear = new Date().getFullYear();
  const [advanced, setAdvanced] = useState(false);
  const [queryResults, setQueryResults] = useState([]);

  const newAudit = () => {
    let company_name = document.getElementById("cname").value;
    let internet = document.getElementById("internet").value;
    if (company_name === "" && internet === "") {
      setMessage("Bitte Firmennamen eingeben!");
      return;
    }
    companyName = company_name;
    const data = {
      "name": company_name,
      "email": document.getElementById("email").value,
      "internet": document.getElementById("internet").value,
    };
    setMessage("Audit wird gestartet ("+company_name+")...");
    document.getElementById("cname").value = "";
    fetch(`${backend_hostname}/process`, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(data),
    })
    .then(response => response.json())
    .then(response => {
      response.error ? (
        setMessage(response.error)
      ) : (
        showResult(response)
      )
    })
    .catch((error) => {
      setMessage("An error has occured.<br/>"+error);
      return {error: error};
    });
  };

  const showAdvanced = () => {
    setAdvanced(!advanced);
  }

  const setMessage = (msg) => {
    document.querySelector(".msg").innerHTML = msg;
  }

  const showResult = (data) => {
    let company_details = data['firmenabc']
    let scamcheck = data['scamcheck']
    let virustotal_data = data['virus_total']
    let ports = data['portscan']
    let headerscheck = data['securityheaders']
    let ssl_certificate = data['ssl_certificate']
    let tls = data['tls']
    let netcraft_data = data['netcraft']
    let trustami_rating = data['trustami']
    let dns_records = data['dns']
    let w3t = data['w3t']
    let manual = data['manual']
    let whatcms = data['whatcms']
    let dnsdumpster = data['dnsdumpster']
    let mxtoolbox = data['mxtoolbox']
    let darknet = data['darknet']
    let domains = data['domains']
    let pagespeed = data['pagespeed']
    let sucuri = data['sucuri']

    // Components
    let score = calculateScore(data);

    const components = <>
      <Firmenidentifikation data={{audit_id: data.audit_id, name: companyName, details: company_details}}/>
      <Score data={{score: score}}/>
      <Website data={{score: score, netcraft: netcraft_data, ssl: ssl_certificate, headerscheck: headerscheck, w3t: w3t, manual: manual, whatcms: whatcms, sucuri: sucuri}} />
      <Seriositat data={{rating: trustami_rating, pagespeed: pagespeed}} />
      <ScamCheck data={{scamcheck: scamcheck}} />
      <SecurityHeaders data={{headerscheck: headerscheck}} />
      <OffentlicheServer data={{dnsdumpster: dnsdumpster}} />
      <DNSRecords data={{dns_records: dns_records}} />
      <SocialMedia data={{whatcms: whatcms}} />
      <VirusAnalysis data={{virus: virustotal_data}} />
      <SSLCheck data={{ssl_certificate: ssl_certificate}} />
      <PortScan data={{ports: ports}} />
      <Email data={{tls: tls, mxtoolbox: mxtoolbox}} />
      <CSP data={{}} />
      <Cookies data={{}} />
      <PostExploitation data={{}} />
      <PostExploitation2 data={{}} />
      <WebAppExploitation data={{}} />
      <SystemNetwork data={{}} />
      <VulnerabilitiesDetection data={{}} />
      <DomainMonitoring data={{domains: domains}} />
      <Darknet data={{darknet: darknet}} />
      <Export />
    </>;

    // Render Components
    // ReactDOM.render(component1, document.getElementById('result'));
    const rootElement = ReactDOM.createRoot(document.getElementById('result'));
    rootElement.render(components);


    // Hide Message
    setMessage("");
    document.getElementById("query").style.display = "none";
  }

  const query = (event) => {
    let company_name = document.getElementById("cname").value;
    if (company_name === "") {
      return;
    }
    fetch(`${backend_hostname}/query?keyword=${company_name}`)
    .then(response => response.json())
    .then(response => {
      if (response.success) {
        setQueryResults(response.results);
      }
    })
  }

  const setKeyword = (result) => {
    return () => {
      document.getElementById("cname").value = result[0];
      setQueryResults([]);
    }
  }

  return (
    <div className="container" id="page-to-pdf">

      <div className="wrapper bg-lightblue mb-1">
        Firmenidentifikation
      </div>

      <div className="row">
        <div className="col mt-4">
          <img src="/images/logo.png" alt="logo" width="200px"></img>
          <div className="title">MEIN RISIKMONITOR</div>
        </div>

        <div className="col mt-4">
          <div className="d-none d-lg-block">
            <table>
              <tbody>
                <tr>
                  <td className="table-key">Datum / Uhrzeit</td>
                  <td className="table-value">{getCurrentDate()}</td>
                </tr>
                <tr>
                  <td className="table-key">Benzutzer</td>
                  <td className="table-value">{userid}</td>
                </tr>
                <tr>
                  <td className="table-key"></td>
                  <td className="table-value">{username}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="d-lg-none">
            <div className="table-key">Datum / Uhrzeit</div>
            <div className="table-value">{getCurrentDate()}</div>
            <div className="table-key">Benzutzer</div>
            <div className="table-value">{userid}</div>
            <div className="table-value">{username}</div>
          </div>
        </div>

        <div className="d-none d-lg-block col text-end">
          <img src="/images/search.png" alt="search" width="300px"></img>
        </div>

      </div>
    
      <div className="wrapper bg-darkblue mt-1"></div>

      <div className="row mb-2" id="query">
        <div className="col">
          <input type="text" className="form-control mt-4" placeholder="Firmenname" id="cname" onKeyUp={query} />

            <ul>
              {queryResults?.map((result, index) => (
                <li key={"qr_" + index} className="clickable result" onClick={setKeyword(result)}>{result[0]}</li>
              ))}
            </ul>

        </div>
          <div className="text-center">
            <div className="col-auto">
              <button type="button" className="btn btn-primary mt-4 w-100 btn-lightblue" id="starten-btn" onClick={newAudit} >Audit Starten</button>
            </div>
            <div className="text-start">
                
              <div className="row mt-4">
                <div className="col-4">
                  <button type="button" className="btn btn-primary btn-darkblue w-100" id="starten-btn" onClick={showAdvanced} >Manuelles Audit</button>
                </div>
                  <div className= {advanced ? "d-block col-4" : "d-none"}><input type="text" className="form-control w-100" placeholder="Email" id="email" /></div>
                  <div className= {advanced ? "d-block col-4" : "d-none"}><input type="text" className="form-control w-100" placeholder="Internet" id="internet" /></div>
                </div>
            </div>
          </div>
      </div>


      <div className="msg"></div>
      <div id="result" className="mb-3"></div>

      <hr/>
      <div className="copyright text-center">&copy; {currentYear} CRIF. All rights reserved.</div> 
    </div>
  );
}

export default App;
