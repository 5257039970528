import React, { useState } from 'react';
import './styles.css';

const DomainMonitoring = ({ data }) => {
    const TITLE = "INFRASTRUKTURDATEN - DOMAINMONITORING"
    const [visible, setVisible] = useState(true);
    const toggleVisibility = () => {
        setVisible(!visible);
    }
    console.log(data)
    return (
        <>
            <div className="component">
                <div className="component-title">
                    <div className="row">
                        <div className="col-auto upper">{TITLE}</div>
                        <div className="col text-end clickable toggler" onClick={toggleVisibility}>{visible ? "Hide" : "Show"}</div>
                    </div>
                </div>
                <div className="component-body" style={{display: visible ? "block" : "none"}}>
                    <div className="component-space">
                        <table className="table table-striped table-hover">
                            <thead>
                                <th>Domain</th>
                                <th>Traffic pM</th>
                                <th>Verfugbarkeit pA</th>
                                <th>Webarchiv</th>
                                <th>aktive homoglyphes</th>
                            </thead>
                            <tbody>
                                {data.domains?.map((record, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{record.domain}</td>
                                            <td></td>
                                            <td></td>
                                            <td><a href={record.archive} target="_blank">{record.archive ? 'Webarchiv Link' : ""}</a></td>
                                            <td><a href={`/api/homoglyphs?domain=${record.domain}`} target="_blank">{record.homoglyph_size}</a></td>
                                        </tr>
                                    )})}
                                
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DomainMonitoring;