import React, { useState } from 'react';
import ReactCountryFlag from "react-country-flag";
import { ComposableMap, Geographies, Geography, Marker } from "react-simple-maps";
import './styles.css';


const Website = ({ data }) => {
    const TITLE = "Infrastrukturdaten - Website"
    const [visible, setVisible] = useState(true);
    const toggleVisibility = () => {
        setVisible(!visible);
    }  

    return (
        <>
            <div className="component">
                <div className="component-title">
                    <div className="row">
                        <div className="col-auto upper">{TITLE}</div>
                        <div className="col text-end clickable toggler" onClick={toggleVisibility}>{visible ? "Hide" : "Show"}</div>
                    </div>
                </div>
                <div className="component-body px-3" style={{display: visible ? "block" : "none"}}>
                    <div className="row">
                        <div className="component-space col centralize text-center">
                            <div classsName="row">
                                <div className="col">
                                    <div className='title'>Virus Detection System</div>
                                    <div className="score-2">{data.score.virus_count} / 63</div>
                                </div>
                            </div>
                        </div>
                        <div className="component-space col centralize text-center">
                            <div classsName="row">
                                <div className="col">
                                    <div className='title'>Scam Check System</div>
                                    <div className="score-2">{data.score.scam_count} / 12</div>
                                </div>
                            </div>
                        </div>
                        <div className="component-space col centralize text-center">
                            <div classsName="row">
                                <div className="col">
                                    <div className='title'>Security Header</div>
                                    <div className="score-2">{data.score.security_headers} / 7</div>
                                </div>
                            </div>
                        </div>
                        <div className="component-space col centralize text-center">
                            <div classsName="row">
                                <div className="col">
                                    <div className='title'>Port & Network Analyse</div>
                                    <div className="score-2">{16 - data.score.close_ports} / 16</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="component-space col">
                            <div className="title">Locations & Typical Traceroute</div>
                            <hr/>
                            <ComposableMap>
                                <Geographies geography="/features.json">
                                    {({ geographies }) =>
                                    geographies.map((geo) => (
                                        <Geography key={geo.rsmKey} geography={geo} />
                                    ))
                                    }
                                </Geographies>
                                {data.manual ? (
                                    <Marker coordinates={[data.manual.latitude, data.manual.longitude]} fill="gray">
                                        <circle r={10} fill="orange" />
                                        <circle r={5} fill="blue" />
                                    </Marker>
                                ) : null}

                            </ComposableMap>
                            <table className='table table-hover'>
                                <tbody>
                                    <tr><td className="table--key">Site</td><td>{data?.manual?.whois ? data?.manual?.whois?.name : ""}</td></tr>
                                    <tr><td className="table--key">Hosting Country</td><td><ReactCountryFlag style={{fontSize: '1.5em'}} countryCode={data?.manual?.whois?.registrant_country ? data.manual.whois.registrant_country : "US"} svg/> {data?.manual?.country_full ? data?.manual?.country_full : data.manual?.whois?.registrant_country}</td></tr>
                                    <tr><td className="table--key">Domain Registrar</td><td>{data?.manual?.whois ? data.manual.whois?.registrar: ""}</td></tr>
                                    <tr><td className="table--key">Nameserver</td><td>{data?.manual.whois ? data.manual.whois?.name_servers[0]: ""}</td></tr>
                                    <tr><td className="table--key">IPv4</td><td>{data?.manual ? data.manual?.ipv4 : ""}</td></tr>
                                    <tr><td className="table--key">Reverse DNS</td><td>{data?.manual ? data.manual?.reserve_dns : ""}</td></tr>
                                    <tr><td className="table--key">Nameserver Admin</td><td>{data?.manual?.whois ? data.manual.whois?.name_servers[0]: ""}</td></tr>
                                </tbody>
                            </table>
                            {/* <br/>
                            <hr/>
                            <div className="title text-center">IP Delegation</div>
                            <hr/>
                            <table className='table table-hover'>
                                <tbody>
                                    {data.netcraft.IPDEL.map((item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{item[0]}</td>
                                                <td>{item[1]}</td>
                                                <td>{item[2]}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table> */}
                        </div>
                        <div className="col">
                            <div className="component-space m-0 mt-2">
                                <div className="title">Web Technology Detector</div>
                                <hr/>
                                <br/>
                                <table className='table table-striped table-hover'>
                                    <tbody>
                                        <tr>
                                            <td className="align-middle"><img alt="icon" src="/images/icons/webserver-type.png" width="30px;"/></td>
                                            <td className="align-middle">Webserver Type</td>
                                            <td className="align-middle">{data.w3t ? data.w3t['Web Server'] : data.sucuri ? data.sucuri.Software.name : ""}</td>
                                            <td className="align-middle">{data.whatcms['web_server'] ? data.whatcms['web_server'][1] : data.sucuri ? data.sucuri.Software.version : ""}</td>
                                        </tr>
                                        <tr>
                                            <td className="align-middle"><ReactCountryFlag style={{fontSize: '2em'}} countryCode={data?.manual ? data.manual?.whois?.registrant_country : "US"} svg/> </td>
                                            <td className="align-middle">Geolocation Webhosting</td>
                                            <td className="align-middle">{data?.manual?.country_full ? data.manual.country_full : data?.manual?.whois?.registrant_country}</td>
                                            <td className="align-middle"></td>
                                        </tr>
                                        <tr>
                                            <td className="align-middle"><img alt="icon" src="/images/icons/service-provider.png" width="30px;"/></td>
                                            <td className="align-middle">Web Hosting Provider</td>
                                            <td className="align-middle">{data.w3t ? data.w3t['Web Hosting Provider'] : ""}</td>
                                            <td className="align-middle"></td>
                                        </tr>
                                        <tr>
                                            <td className="align-middle"><img alt="icon" src="/images/icons/service-provider.png" width="30px;"/></td>
                                            <td className="align-middle">Email Server Provider</td>
                                            <td className="align-middle">{data.w3t ? data.w3t['Email Server Provider'] : ""}</td>
                                            <td className="align-middle"></td>
                                        </tr>
                                        <tr>
                                            <td className="align-middle"><img alt="icon" src="/images/icons/cms.png" width="30px;"/></td>
                                            <td className="align-middle">Content Mgmt. System</td>
                                            <td className="align-middle">{data.w3t ? data.w3t['Content Management Systems'] : data.sucuri ? data.sucuri.CMS.name : ""}</td>
                                            <td className="align-middle">{data.w3t ? data.w3t['CMSVersion'] : data.sucuri ? data.sucuri.CMS.version : ""}</td>
                                        </tr>
                                        <tr>
                                            <td className="align-middle"><img alt="icon" src="/images/icons/language.png" width="30px;"/></td>
                                            <td className="align-middle">Server-side Programming Language</td>
                                            <td className="align-middle">{data.w3t ? data.w3t['Server-side Programming Language'] : data.w3t}</td>
                                            <td className="align-middle">{data.whatcms['programming_language'] ? data.whatcms['programming_language'][1] : ""}</td>
                                        </tr>
                                        <tr>
                                            <td className="align-middle"><img alt="icon" src="/images/icons/language.png" width="30px;"/></td>
                                            <td className="align-middle">Client-side Programming Language</td>
                                            <td className="align-middle">{data.w3t ? data.w3t['Client-side Programming Language'] : data.w3t}</td>
                                            <td className="align-middle"></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="row">
                                <div className="col component-space">
                                    <div className="row centralize">
                                        <div className="col-auto m-2"><img alt="icon" src="/images/icons/SSL.png" width="40px;" /></div>
                                        <div className="col text-center">
                                            SSL {data.ssl["SSL Resolves to"] ? "active" : "inactive"}<br/>
                                            TLS 1.2 active<br/>
                                            HSTS {data.headerscheck['HSTS'] === "aktiv" ? "active" : "inactive"}
                                        </div>
                                    </div>
                                </div>
                                <div className="col component-space">
                                    <div className="row centralize">
                                        <div className="col-auto m-2"><img alt="icon" src="/images/icons/malware.png" width="40px;" /></div>
                                        <div className="col text-center">No Malware found</div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col component-space">
                                    <div className="row centralize">
                                        <div className="col-auto m-2"><img alt="icon" src="/images/icons/certificate.png" width="40px;" /></div>
                                        <div className="col text-center">
                                            SSL/TLS certificate authorities<br/>
                                            {data.ssl["SSL Issuer Company"]} till {data.ssl["Expiration Date"]}
                                        </div>
                                    </div>
                                </div>
                                <div className="col component-space">
                                    <div className="row centralize">
                                        <div className="col-auto m-2"><img alt="icon" src="/images/icons/protection.png" width="40px;" /></div>
                                        <div className="col text-center">Web Anti-Virus [yes]<br/>Web Firewall [{data.sucuri ? data.sucuri.Firewall === "Not Detected" ? "yes" : "no" : "no"}]<br/>Monitoring [{data.sucuri ? data.sucuri.Monitoring === "Not Detected" ? "yes" : "no" : "no"}]</div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col component-space">
                                    <div className="row centralize">
                                        <div className="col-auto m-2"><img alt="icon" src="/images/icons/cookie.png" width="40px;" /></div>
                                        <div className="col text-center">Cookie information [yes]</div>
                                    </div>
                                </div>
                                <div className="col component-space">
                                    <div className="row centralize">
                                        <div className="col-auto m-2"><img alt="icon" src="/images/icons/google-fonts.png" width="40px;" /></div>
                                        <div className="col text-center">Use google Fonts [yes]</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
};

export default Website;