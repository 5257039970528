import React, { useState } from 'react';
import './styles.css';

const Score = ({ data }) => {
    const TITLE = "Cybersecurity Score"
    const [visible, setVisible] = useState(true);
    const toggleVisibility = () => {
        setVisible(!visible);
    }
    return (
        <>
            <div className="component">
                <div className="component-title">
                    <div className="row">
                        <div className="col-auto upper">{TITLE}</div>
                        <div className="col text-end clickable toggler" onClick={toggleVisibility}>{visible ? "Hide" : "Show"}</div>
                    </div>
                </div>
                <div className="component-body" style={{display: visible ? "block" : "none"}}>
                    <div className="component-space px-5">
                        <div className="row">
                            <div className='col-auto text-end m-auto'>
                                <div className={`score bg-${data.score.letter}`}>
                                    <div className="score-letter">{data.score.letter}</div>
                                </div>
                                    <div className="score-number text-start">Sicherheitsindex: <b>{data.score.score}</b></div>
                            </div>
                            <div className='col text-start'>
                                
                                <div className="row mt-4">
                                    {/* SELECTED */}
                                    <div className={`col color-cell bg-A ${data.score.letter === 'A' ? 'selected' : ''}`}>A</div>
                                    <div className={`col color-cell bg-B ${data.score.letter === 'B' ? 'selected' : ''}`}>B</div>
                                    <div className={`col color-cell bg-C ${data.score.letter === 'C' ? 'selected' : ''}`}>C</div>
                                    <div className={`col color-cell bg-D ${data.score.letter === 'D' ? 'selected' : ''}`}>D</div>
                                    <div className={`col color-cell bg-E ${data.score.letter === 'E' ? 'selected' : ''}`}>E</div>
                                    <div className={`col color-cell bg-F ${data.score.letter === 'F' ? 'selected' : ''}`}>F</div>
                                    <div className={`col color-cell bg-G ${data.score.letter === 'G' ? 'selected' : ''}`}>G</div>
                                    <div className={`col color-cell bg-H ${data.score.letter === 'H' ? 'selected' : ''}`}>H</div>
                                    <div className={`col color-cell bg-I ${data.score.letter === 'I' ? 'selected' : ''}`}>I</div>
                                </div>
                                
                                <hr className='mt-3 mb-3'/>
                                <b>Der Cyberindex {data.score.score} bedeutet „tragbare Sicherheit“</b><br/>
                                Die Skala gibt die branchenspezifische Wahrscheinlichkeit, ob der Partner innerhalb der nächsten 12 Monate einen Sicherheitsvorfall abwehren kann.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Score;