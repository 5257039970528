import React, { useState } from 'react';
import './styles.css';
import DownloadPDF from './DownloadPDF.js';

const Export = ({ data }) => {
    const TITLE = "EXPORT"
    const [visible, setVisible] = useState(true);
    const toggleVisibility = () => {
        setVisible(!visible);
    }
    return (
        <>
            <div className="component">
                <div className="component-title">
                    <div className="row">
                        <div className="col-auto upper">{TITLE}</div>
                        <div className="col text-end clickable toggler" onClick={toggleVisibility}>{visible ? "Hide" : "Show"}</div>
                    </div>
                </div>
                <div className="component-body" style={{display: visible ? "block" : "none"}}>
                    <div className="component-space text-center">
                        {/* <button className="btn btn-secondary w-25">Export as PDF</button> */}
                        <DownloadPDF />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Export;