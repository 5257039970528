import React, { useState } from 'react';
import './styles.css';

const Seriositat = ({ data }) => {
    const TITLE = "Infrastrukturdaten - SERIOSITÄT & VERTRAUEN"
    const [visible, setVisible] = useState(true);
    const toggleVisibility = () => {
        setVisible(!visible);
    }
    return (
        <>
            <div className="component">
                <div className="component-title">
                    <div className="row">
                        <div className="col-auto upper">{TITLE}</div>
                        <div className="col text-end clickable toggler" onClick={toggleVisibility}>{visible ? "Hide" : "Show"}</div>
                    </div>
                </div>
                <div className="component-body d-none d-md-block" style={{display: visible ? "block" : "none"}}>
                    <div className="row px-3">
                        <div className="col component-space">
                            <div className="row component-space">
                                <div className="col-auto m-2"><img src="/images/icons/domain-authority.png" alt="icon" width="60px"/></div>
                                <div className="col text-center">
                                    <div className="score-3">30 / 100</div>
                                    <div className="title">Domain Authority</div>
                                </div>
                            </div>
                        </div>
                        <div className="col component-space">
                            <div className="row component-space">
                                <div className="col-auto m-2"><img src="/images/icons/backlinks.png" alt="icon" width="60px"/></div>
                                <div className="col text-center">
                                    <div className="score-3">5.103</div>
                                    <div className="title">Backlinks</div>
                                </div>
                            </div>
                        </div>
                        <div className="col component-space">
                            <div className="row component-space">
                                <div className="col-auto m-2"><img src="/images/icons/reviews.png" alt="icon" width="60px"/></div>
                                <div className="col text-center">
                                    <div className="score-3">{data.rating} / 5,00</div>
                                    <div className="title">Bewertungen</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row px-3">
                        <div className="col component-space">
                            <div className="row component-space">
                                <div className="col-auto m-2"><img src="/images/icons/google-score-mobile.png" alt="icon" width="60px"/></div>
                                <div className="col text-center">
                                    <div className="score-3">72 / 100</div>
                                    <div className="title">Google Score Mobile</div>
                                </div>
                            </div>
                        </div>
                        <div className="col component-space">
                            <div className="row component-space">
                                <div className="col-auto m-2"><img src="/images/icons/desktop.png" alt="icon" width="60px"/></div>
                                <div className="col text-center">
                                    <div className="score-3">96 / 100</div>
                                    <div className="title">Google Score Desktop</div>
                                </div>
                            </div>
                        </div>
                        <div className="col component-space">
                            <div className="row component-space">
                                <div className="col-auto m-2"><img src="/images/icons/reviews.png" alt="icon" width="60px"/></div>
                                <div className="col text-center">
                                    <div className="score-3">93 / 100</div>
                                    <div className="title">Barrierefreiheit</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="component-body d-md-none" style={{display: visible ? "block" : "none"}}>
                    <div className="col component-space">
                        <div className="row component-space">
                            <div className="col-auto m-2"><img src="/images/icons/domain-authority.png" alt="icon" width="60px"/></div>
                            <div className="col text-center">
                                <div className="score-3">21 / 100</div>
                                <div className="title">Domain Authority</div>
                            </div>
                        </div>
                    </div>
                    <div className="col component-space">
                        <div className="row component-space">
                            <div className="col-auto m-2"><img src="/images/icons/backlinks.png" alt="icon" width="60px"/></div>
                            <div className="col text-center">
                                <div className="score-3">5.103</div>
                                <div className="title">Backlinks</div>
                            </div>
                        </div>
                    </div>
                    <div className="col component-space">
                        <div className="row component-space">
                            <div className="col-auto m-2"><img src="/images/icons/reviews.png" alt="icon" width="60px"/></div>
                            <div className="col text-center">
                                <div className="score-3">{data.rating} / 5,00</div>
                                <div className="title">Bewertungen</div>
                            </div>
                        </div>
                    </div>
                    <div className="col component-space">
                        <div className="row component-space">
                            <div className="col-auto m-2"><img src="/images/icons/google-score-mobile.png" alt="icon" width="60px"/></div>
                            <div className="col text-center">
                                <div className="score-3">72 / 100</div>
                                <div className="title">Google Score Mobile</div>
                            </div>
                        </div>
                    </div>
                    <div className="col component-space">
                        <div className="row component-space">
                            <div className="col-auto m-2"><img src="/images/icons/desktop.png" alt="icon" width="60px"/></div>
                            <div className="col text-center">
                                <div className="score-3">96 / 100</div>
                                <div className="title">Google Score Desktop</div>
                            </div>
                        </div>
                    </div>
                    <div className="col component-space">
                        <div className="row component-space">
                            <div className="col-auto m-2"><img src="/images/icons/reviews.png" alt="icon" width="60px"/></div>
                            <div className="col text-center">
                                <div className="score-3">93 / 100</div>
                                <div className="title">Barrierefreiheit</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='container'>
                    <table className="table table-hover table-striped">
                        <thead>
                            <th>Metric</th>
                            <th>Result</th>
                        </thead>
                        <tbody>
                            <tr> <td className='w-25'>First Contentful Paint</td> <td>{data.pagespeed ? data.pagespeed['First Contentful Paint'] : ""}</td> </tr>
                            <tr> <td className='w-25'>First Input Delay</td> <td>{data.pagespeed ? data.pagespeed['First Input Delay'] : ""}</td> </tr>
                            <tr> <td className='w-25'>First Meaningful Paint</td> <td>{data.pagespeed ? data.pagespeed['First Meaningful Paint'] : ""}</td> </tr>
                            <tr> <td className='w-25'>Performance Score</td> <td>{data.pagespeed ? data.pagespeed['Performance Score'] : ""}</td> </tr>
                            <tr> <td className='w-25'>Speed Index</td> <td>{data.pagespeed ? data.pagespeed['Speed Index'] : ""}</td> </tr>
                            <tr> <td className='w-25'>Time To Interactive</td> <td>{data.pagespeed ? data.pagespeed['Time To Interactive'] : ""}</td> </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};

export default Seriositat;