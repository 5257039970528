// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper {
    height: 35px;
    padding: 5px;
    font-weight: bold;
    color: white;
}

.bg-lightblue {
    background-color: rgb(160, 160, 160);
}

.btn-lightblue {
    background-color: #228B22 !important;
    border-color: #196819 !important;
    color: white !important;
    cursor: pointer;
}

.btn-lightblue:hover {
    background-color: #196819 !important;
}

.btn-darkblue {
    background-color: rgb(217, 217, 217) !important;
    border-color: rgb(191, 191, 191) !important;
    color: gray !important;
}

.btn-darkblue:hover {
    background-color: rgb(191, 191, 191) !important;
}

.bg-darkblue {
    background-color: rgb(28, 71, 127);
}

.title {
    font-weight: bold;
    color: gray;
    margin-top: 5px;
}

.location {
    color: rgb(70, 133, 198);
    font-size: 12px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.table-key {
    font-weight: bold;
    color: gray;
    font-size: 12px;
    width: 120px;
}

.table-value {
    font-size: 12px;
}

.copyright {
    font-size: 12px;
    color: gray;
    margin-top: 10px;
    margin-bottom: 10px;
}

#cname {
    min-width: 60vw;
}

.result {
    color: gray;
    font-size: 12px;
}

.result:hover {
    color: black;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,YAAY;IACZ,iBAAiB;IACjB,YAAY;AAChB;;AAEA;IACI,oCAAoC;AACxC;;AAEA;IACI,oCAAoC;IACpC,gCAAgC;IAChC,uBAAuB;IACvB,eAAe;AACnB;;AAEA;IACI,oCAAoC;AACxC;;AAEA;IACI,+CAA+C;IAC/C,2CAA2C;IAC3C,sBAAsB;AAC1B;;AAEA;IACI,+CAA+C;AACnD;;AAEA;IACI,kCAAkC;AACtC;;AAEA;IACI,iBAAiB;IACjB,WAAW;IACX,eAAe;AACnB;;AAEA;IACI,wBAAwB;IACxB,eAAe;IACf,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;IACjB,WAAW;IACX,eAAe;IACf,YAAY;AAChB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,WAAW;IACX,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,eAAe;AACnB;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".wrapper {\n    height: 35px;\n    padding: 5px;\n    font-weight: bold;\n    color: white;\n}\n\n.bg-lightblue {\n    background-color: rgb(160, 160, 160);\n}\n\n.btn-lightblue {\n    background-color: #228B22 !important;\n    border-color: #196819 !important;\n    color: white !important;\n    cursor: pointer;\n}\n\n.btn-lightblue:hover {\n    background-color: #196819 !important;\n}\n\n.btn-darkblue {\n    background-color: rgb(217, 217, 217) !important;\n    border-color: rgb(191, 191, 191) !important;\n    color: gray !important;\n}\n\n.btn-darkblue:hover {\n    background-color: rgb(191, 191, 191) !important;\n}\n\n.bg-darkblue {\n    background-color: rgb(28, 71, 127);\n}\n\n.title {\n    font-weight: bold;\n    color: gray;\n    margin-top: 5px;\n}\n\n.location {\n    color: rgb(70, 133, 198);\n    font-size: 12px;\n    margin-top: 10px;\n    margin-bottom: 10px;\n}\n\n.table-key {\n    font-weight: bold;\n    color: gray;\n    font-size: 12px;\n    width: 120px;\n}\n\n.table-value {\n    font-size: 12px;\n}\n\n.copyright {\n    font-size: 12px;\n    color: gray;\n    margin-top: 10px;\n    margin-bottom: 10px;\n}\n\n#cname {\n    min-width: 60vw;\n}\n\n.result {\n    color: gray;\n    font-size: 12px;\n}\n\n.result:hover {\n    color: black;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
