import React, { useState } from 'react';
import './styles.css';

const Email = ({ data }) => {
    const TITLE = "INFRASTRUKTURDATEN - E-MAIL"
    const [visible, setVisible] = useState(true);
    const toggleVisibility = () => {
        setVisible(!visible);
    }

    let records = [];
    let record = [];
    const keys = Object.keys(data.mxtoolbox);
    for (let i=1; i<keys.length; i++) {
        record.push(data.mxtoolbox[keys[i]]);
        if (record.length === 3) {
            records.push(record);
            record = [];
        }
    }

    return (
        <>
            <div className="component">
                <div className="component-title">
                    <div className="row">
                        <div className="col-auto upper">{TITLE}</div>
                        <div className="col text-end clickable toggler" onClick={toggleVisibility}>{visible ? "Hide" : "Show"}</div>
                    </div>
                </div>
                <div className="component-body" style={{display: visible ? "block" : "none"}}>
                    <div className="component-space">
                        <div className="title">MX-Records</div>
                        <div className="d-none d-md-block">
                            <table className="table table-striped table-hover">
                                <thead>
                                    <th>MX Server</th>
                                    <th>Pref</th>
                                    <th>Answer</th>
                                    <th>Connect</th>
                                    <th>HELO</th>
                                    <th>TLS 1.0-1.3</th>
                                    <th>Cert</th>
                                    <th>Secure</th>
                                    <th>From</th>
                                    <th>MTASTS</th>
                                    <th>DANE</th>
                                </thead>
                                <tbody>
                                    {data.tls?.map((value, index) => (
                                        <tr key={index}>
                                            <td>{value['MX Server']}</td>
                                            <td>{value.Pref}</td>
                                            <td className={`${value.Answer === "OK" ? "text-success" : "text-secondary"}`}>{value.Answer}</td>
                                            <td className={`${value.Connect === "OK" ? "text-success" : "text-secondary"}`}>{value.Connect}</td>
                                            <td className={`${value.HELO === "OK" ? "text-success" : "text-secondary"}`}>{value.HELO}</td>
                                            <td className={`${value.TLS === "OK" ? "text-success" : "text-secondary"}`}>{value.TLS}</td>
                                            <td className={`${value.Cert === "SUCCESS" ? "text-success" : "text-secondary"}`}>{value.Cert}</td>
                                            <td className={`${value.Secure === "OK" ? "text-success" : "text-secondary"}`}>{value.Secure}</td>
                                            <td className={`${value.From === "OK" ? "text-success" : "text-secondary"}`}>{value.From}</td>
                                            <td className={`${value.MTASTS === "OK" ? "text-success" : "text-secondary"}`}>{value.MTASTS}</td>
                                            <td className={`${value.DANE === "OK" ? "text-success" : "text-secondary"}`}>{value.DANE}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="d-md-none mt-3 mb-3">
                            {data.tls?.map((value, index) => (
                                <>
                                    <div key={index} className="row">
                                        <div><span className="title">MX Server:</span> <span>{value['MX Server']}</span></div>
                                        <div><span className="title">Pref:</span> <span>{value.Pref}</span></div>
                                        <div><span className="title">Answer:</span> <span className={`${value.Answer === "OK" ? "text-success" : "text-secondary"}`}>{value.Answer}</span></div>
                                        <div><span className="title">Connect:</span> <span className={`${value.Connect === "OK" ? "text-success" : "text-secondary"}`}>{value.Connect}</span></div>
                                        <div><span className="title">HELO:</span> <span className={`${value.HELO === "OK" ? "text-success" : "text-secondary"}`}>{value.HELO}</span></div>
                                        <div><span className="title">TLS 1.0-1.3:</span> <span className={`${value.TLS === "OK" ? "text-success" : "text-secondary"}`}>{value.TLS}</span></div>
                                        <div><span className="title">Cert:</span> <span className={`${value.Cert === "SUCCESS" ? "text-success" : "text-secondary"}`}>{value.Cert}</span></div>
                                        <div><span className="title">Secure:</span> <span className={`${value.Secure === "OK" ? "text-success" : "text-secondary"}`}>{value.Secure}</span></div>
                                        <div><span className="title">From:</span> <span className={`${value.From === "OK" ? "text-success" : "text-secondary"}`}>{value.From}</span></div>
                                        <div><span className="title">MTASTS:</span> <span className={`${value.MTASTS === "OK" ? "text-success" : "text-secondary"}`}>{value.MTASTS}</span></div>
                                        <div><span className="title">DANE:</span> <span className={`${value.DANE === "OK" ? "text-success" : "text-secondary"}`}>{value.DANE}</span></div>
                                    </div>
                                    <hr/>
                                </>
                            ))}
                        </div>
                        <div className="title">Blacklists</div>
                        {/* <table className="table table-striped table-hover">
                            <thead>
                                <th>Source</th>
                                <th>Value</th>
                                <th>Source</th>
                                <th>Value</th>
                                <th>Source</th>
                                <th>Value</th>
                            </thead>
                            <tbody>
                            </tbody>
                        </table> */}

                        <table className="m-auto table table-hover">
                            <tbody>
                                {records?.map((record, index) => (
                                    <tr key={index}>
                                        <td className={`${record[0][0] === 'OK' ? "text-success" : record[0][0] === "Nicht OK" ? "text-danger" : "text-secondary"}`}>{record[0][0]}</td>
                                        <td>{record[0][1]}</td>
                                        <td className={`${record[1][0] === 'OK' ? "text-success" : record[1][0] === "Nicht OK" ? "text-danger" : "text-secondary"}`}>{record[1][0]}</td>
                                        <td>{record[1][1]}</td>
                                        <td className={`${record[2][0] === 'OK' ? "text-success" : record[2][0] === "Nicht OK" ? "text-danger" : "text-secondary"}`}>{record[2][0]}</td>
                                        <td>{record[2][1]}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>
        </>
    );
};

export default Email;