import React, { useState } from 'react';
import './styles.css';

const WebAppExploitation = ({ data }) => {
    const TITLE = "INFRASTRUKTURDATEN - EXPLOITATION - Web application"
    const [visible, setVisible] = useState(true);
    const toggleVisibility = () => {
        setVisible(!visible);
    }
    const head = ['Test', 'Status']
    const records = [
        ['Convert.base64-encode', 'bestanden'],
        ['Filesearch passwd', 'bestanden'],
        ['php key checker', 'bestanden'],
        ['sqlinjection', 'bestanden']
    ]
    return (
        <>
            <div className="component">
                <div className="component-title">
                    <div className="row">
                        <div className="col-auto upper">{TITLE}</div>
                        <div className="col text-end clickable toggler" onClick={toggleVisibility}>{visible ? "Hide" : "Show"}</div>
                    </div>
                </div>
                <div className="component-body" style={{display: visible ? "block" : "none"}}>
                    <div className="component-space">
                        <table className="table table-hover">
                            <thead>
                                {head?.map((item, index) => (
                                    <th key={index}>{item}</th>
                                ))}
                            </thead>
                            <tbody>
                                {records?.map((record, index) => (
                                    <tr key={index}>
                                        {record?.map((item, index) => (
                                            <td key={index}>{item}</td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
};

export default WebAppExploitation;