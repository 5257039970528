import React, { useState } from 'react';
import './styles.css';

const Firmenidentifikation = ({ data }) => {
    const [visible, setVisible] = useState(true);
    const toggleVisibility = () => {
        setVisible(!visible);
    }
    return (
        <>
            <div className="component">
                <div className="component-title">
                    <div className="row">
                        <div className="col-auto upper">Fidentifikation</div>
                        <div className="col text-end clickable toggler" onClick={toggleVisibility}>{visible ? "Hide" : "Show"}</div>
                    </div>
                </div>
                <div className="component-body" style={{display: visible ? "block" : "none"}}>
                    <div className="component-space">
                        <div className="row">
                            <div className="col">
                                <div className="table--key">{data.name}</div>
                                {data.details.address && data.details.address?.map((address, index) => (
                                    <div key={index}>{address}</div>
                                ))}
                            </div>
                            <div className="col">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td className="table--key">Telefon</td>
                                            <td>{data.details.Telefon}</td>
                                        </tr>
                                        <tr>
                                            <td className="table--key">Telefax</td>
                                            <td>{data.details.Telefax}</td>
                                        </tr>
                                        <tr>
                                            <td className="table--key">E-Mail</td>
                                            <td>{data.details["E-Mail"]}</td>
                                        </tr>
                                        <tr>
                                            <td className="table--key">Internet</td>
                                            <td><a href={data.details.Internet} target="_blank" rel="noreferrer">{data.details.Internet}</a></td>
                                        </tr>
                                        <tr>
                                            <td className="table--key">Mobil</td>
                                            <td>{data.details.Mobil}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="component-space">
                        <div className="row">
                            <div className="col">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td className="table--key">Auditreport Nummer</td>
                                            <td>{data.audit_id}</td>
                                        </tr>
                                        <tr>
                                            <td className="table--key">Handelsname</td>
                                            <td>{data.details.Handelsname}</td>
                                        </tr>
                                        <tr>
                                            <td className="table--key">Firmenstatus</td>
                                            <td>{data.details.Firmenstatus}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="col">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td className="table--key">Amtsgericht</td>
                                            <td>{data.details.Amtsgericht}</td>
                                        </tr>
                                        <tr>
                                            <td className="table--key">Steuernummer</td>
                                            <td>{data.details.Steuernummer}</td>
                                        </tr>
                                        <tr>
                                            <td className="table--key">USt-ID Nr.</td>
                                            <td>{data.details["USt-ID Nr."]}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Firmenidentifikation;